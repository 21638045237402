.dd-lst-container {
    position: relative;
}

.dd-lst-container.dd-readonly {
    background-color: #e3e7ed;
}

.dd-lst-text {
    color: var(--hg-color-1);
    font-weight: 500;
    flex: 1 1;
    padding: 5px 0px;
}

.dd-lst-propositions {
    display: none;
    position: absolute;
    background-color: #FFF;
    border: 1px solid #ddd;
    top: 100%;
    left: -20px;
    z-index: 300;
    box-sizing: border-box;
}

.show-propositions .dd-lst-propositions {
    display: block;
}

.dd-lst-disp {
    display: flex;
}

.dd-lst-caret {
    padding: 5px 0;
    margin-inline-start: 5px;
    cursor: pointer;
    flex: 0 0 9px;
}

.entity-selection-block .dd-lst-caret {
    position: relative;
    top: -28px;
    font-size: 18px;
    height: 20px;
}

.entity-selection-block .dd-lst-text {
    padding: 0;
}

.dd-lst-search {
    position: relative;
    margin: 10px;
}

.dd-lst-search input {
    padding: 5px 40px 5px 10px;
    box-sizing: border-box;
    height: 30px;
    border: 1px solid #0008;
    border-radius: 4px;
    color: #777;
    width: 100%;
}

.dd-lst-search i {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 12px;
    cursor: pointer;
    opacity: 0.5;
}

.dd-lst-options {
    margin: 0px 0;
    max-height: 50vh;
    overflow: auto;
}

ul.dd-lst-options-ul {
    padding: 0;
    margin-bottom: 0px;
}

.dd-lst-options-ul li {
    list-style: none;
    cursor: pointer;
    font-size: 0.95em;
    padding: 5px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    padding: 5px 5px 5px 15px;
    font-size: 13px;
    color: #444;
    font-weight: 500;
}

.dd-lst-options-ul li:hover {
    background: #eee;
}

.dd-lst-options-ul .dd-lst-options-ul li {
    /* padding-left: 5px ;*/
}

.entity-selection-block>.row {
    display: block;
}

.entity-selection-block>.az-content-header-right {
    text-align: left;
}

.entity-selection-block>.row .col-2.media {
    max-width: inherit;
}

@media (max-width: 768px) {
    .entity-selection-block>.row .col-2.media {
        border: 1px solid #bbc;
        margin-top: 10px;
    }
    .entity-selection-block>.row .col-2.media:first-child {
        border: none;
    }
    .entity-selection-block>div.row {
        border-bottom: none;
    }
    .entity-selection-block>.row .col-2.media label {
        font-size: 0.9em;
        font-weight: 600;
    }
    .entity-selection-block .dd-lst-propositions {
        width: 100%;
        left: 0;
    }
}

@media (min-width: 768px) {
    .entity-selection-block>.row {
        display: flex;
    }
    .entity-selection-block>.row .col-2.media {
        max-width: 16.6%;
    }
}

.entity-selection-block>div {
    justify-content: center;
    /* border-bottom: 1px solid #ccc; */
    margin-bottom: 20px;
    /*  border-top: 1px solid #ccc; */
    margin-right: 5px;
    margin-left: 0px;
}

.entity-selection-block .az-content-header-right .media+.media {
    margin-left: 0px;
}