.time-line {
    position: relative;
}

.column-title {
    color: var(--fg-color-1);
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 1rem;
    padding-left: 1rem;
    font-family: 'Montserrat';
    font-weight: 700;
    text-transform: uppercase;
}

.feed-column {
    padding: 20px 0;
    height: calc(100vh - 75px);
    overflow: auto;
    direction: rtl;
}

.feed-column>div {
    direction: ltr;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.tendence-column {
    padding: 20px;
    position: fixed;
    top: 0px;
    right: 60px;
    width: 480px;
    bottom: 0;
    overflow: auto;
    display: none;
}

.control-column {
    width: 250px;
    z-index: 100;
}

.box-card {
    position: relative;
    margin-block-end: 0px;
    margin-block-start: 0px;
    border-radius: 0px;
    background: #FFF;
    padding: 10px;
    /*border: 1px solid #ddd;
    box-shadow: 0 0 10px #ddd;*/
}

.time-line .box-card {
    box-shadow: 0 0 10px #ddd;
}

.box-card:hover {
    border: 1px solid #ddd;
}

.box-card .box-card {
    border: 1px solid #ddd;
}

.control-column .channel-card.box-card {
    display: none;
}

.control-column.active .channel-card.box-card {
    display: block;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 100;
}

.control-column .box-card-show-btn {
    display: none;
}

.control-column.active .box-card-show-btn {
    display: none;
}

.control-column.disabled .box-card-show-btn {
    display: block;
    width: 40px;
    height: 40px;
    background: var(--bg-color-scoll-2);
    border: #ddd 1px solid;
    text-align: center;
    line-height: 40px;
    border-radius: 30px;
    position: fixed;
    top: 70px;
    cursor: pointer;
    box-shadow: 0 0 10px #aaa;
    font-size: 20px;
    right: 2px;
    color: #FFF;
    z-index: 10;
}

.control-column.disabled .box-card-show-btn.fa-users-cog {
    top: 120px
}

.kpi-details .card-table-one {
    padding: 10px 0px 10px;
    height: 100%;
    border-color: #fff;
    box-shadow: none;
}

.card.card-dashboard-one.indicator-card {
    border: none;
}

.card.card-dashboard-one.indicator-card>div {
    padding-top: 0;
}

.control-column .box-card {
    width: 100%;
}

.box-header {
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
}

.box-title {
    padding: 10px 15px;
    color: var(--fg-color-2);
    font-size: 1.3rem;
    font-weight: 600;
}

.box-detail {
    padding: 5px 15px;
    color: #000C;
}

.box-detail>div {
    margin-bottom: 5px;
    cursor: pointer;
}

.box-detail i {
    font-size: 17px;
    margin-inline-end: 8px;
    display: inline-block;
    width: 22px;
    color: #0009;
}

.box-detail>div:hover>* {
    color: var(--fg-color-2);
}


/**.share-box-feed-entry**/

.share-box-feed-entry-card {
    width: 100%;
}

.share-box-feed-entry-card-top {
    margin: 8px 16px;
    display: flex;
}

.share-box-feed-img-user {
    width: 55px;
    height: 50px;
    flex: 0 0 55px;
    padding: 5px;
    display: block;
    position: relative;
    border-radius: 100%;
}

.share-box-feed-img-user.large-avatar {
    width: 80px;
    height: 80px;
    padding: 0;
    margin: auto;
}

.share-box-feed-img-user.small-avatar {
    width: 32px;
    height: 32px;
    padding: 0;
    margin: auto;
}

.share-box-feed-img-user.medium-avatar {}

.share-box-feed-img-user img {
    width: auto;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
}

.shared-feed-actor-info {
    padding-block-start: 5px;
    line-height: 15px;
}

.input-like-button {
    background: #eee8;
    border: 2px dashed #151d30;
    border-radius: 5px;
    height: 26px;
    padding: 0;
    box-sizing: border-box;
    width: 30px;
    text-align: center;
    font-size: 13px;
    color: #151d30;
    margin: auto;
    display: inline-block;
    line-height: 18px;
}

.input-like-button>span {
    color: #0009;
    font-size: 14px;
    font-weight: 600;
}

.subtitle-add-post {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 800;
    text-align: center;
    margin: auto;
    font-family: 'MONTSERRAT';
}

.share-box-feed-entry-toolbar {
    margin: 8px 16px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.share-box-feed-entry-toolbar .vsep {
    display: none;
}

.share-box-feed-entry-toolbar-block i {
    font-size: 24px;
    line-height: 1;
}

.share-box-feed-entry-toolbar-block>span {
    padding: 0px 5px;
    vertical-align: text-bottom;
}

.share-box-feed-entry-toolbar-block {
    background-color: #FFF;
    border: none;
    cursor: pointer;
    padding: 2px 12px;
}

label.share-box-feed-entry-toolbar-block {
    margin-bottom: 0px;
}

.share-box-feed-entry-toolbar-block:hover {
    background: #eee;
    border-radius: 40px;
}

.share-box-feed-entry-toolbar-block.post {
    background: #98c498;
    color: #FFF;
    border-radius: 40px;
}

.share-box-feed-entry-toolbar-block.post:hover {
    background: var(--fg-color-1);
    color: #FFF;
    border-radius: 40px;
}

.post-error-message {
    padding: 5px 15px;
    text-align: center;
    background-color: var(--fg-color-1);
    color: #FFF;
}

.comment-reedit {
    flex: 1 1;
}

span.comment-count {
    cursor: pointer;
}

span.comment-author {
    flex: 1 1;
}

span.cnl {
    color: rgba(0, 0, 0, .6);
    font-size: 12px;
    font-weight: 600;
}

span.pcnl {
    font-weight: 600;
}


/***order-by-block***/

.order-by-block {
    display: flex;
    position: relative;
    margin-bottom: 1rem !important;
}

.my-channels {
    font-size: 12px;
    padding-right: 8px;
    border-right: 2px solid #aaa;
    display: flex;
    justify-content: space-around;
    flex: 1 1;
    overflow: auto;
}

.my-channels>span {
    cursor: pointer;
    font-weight: 500;
}

.order-by-block hr {
    flex-grow: 1;
    background-color: #eee;
    height: 1px;
}

.order-by-label {
    color: #888;
    cursor: pointer;
}

.order-by-value {
    font-weight: bold;
    color: #333;
    cursor: pointer;
}

.order-by-block span {
    margin: 0.3rem;
}

.order-by-block i {
    margin: 0.3rem;
}

.order-by-block .drop-lst {
    position: absolute;
    background: #202040;
    color: #DDD;
    z-index: 100;
    padding: 5px 10px;
    top: 29px;
    right: 10px;
    font-weight: 600;
    border-radius: 5px;
    line-height: 20px;
}

.order-by-block .drop-lst>div {
    cursor: pointer;
    line-height: 25px;
    border-bottom: 1px solid #eee;
}

.order-by-block .drop-lst>div:last-child {
    border-bottom: none;
}

.order-by-block .drop-lst>div:hover {
    color: #FFF;
}


/*****SHARED FEED*****/

.shared-feed-card {
    width: 100%;
}

.feeding-actions {
    width: 28px;
    height: 28px;
    position: absolute;
    right: 8px;
    top: 4px;
    background: #FFF;
    border-radius: 25px;
    text-align: center;
    font-size: 15px;
    line-height: 25px;
    color: #888;
    z-index: 10;
    box-sizing: content-box;
    padding: 8px;
}

.comment-actions {
    position: relative;
    width: 20px;
    height: 20px;
    margin-left: 1rem;
    top: -5px;
    left: 0;
    line-height: 20px;
    background: #f8f8f8;
}

.feeding-actions .inner {
    display: none;
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    margin: auto;
    margin-bottom: 6px;
    border-radius: 20px;
    cursor: pointer;
    background-color: #ddd;
    color: var(--fg-color-2);
    opacity: 0.7;
}

.feeding-actions .inner.marked-post {
    color: red;
}

.feeding-actions .inner:hover {
    background-color: #ccc;
    border: 1px solid #bbb;
    opacity: 1;
}

.feeding-actions .inner:last-child {
    margin-bottom: 0px;
}

.feeding-actions:hover {
    background: #eee8;
    height: fit-content;
}

.feeding-actions:hover .fa-ellipsis-h {
    display: none;
}

.feeding-actions:hover .inner {
    display: block;
}

.shared-feed-header {
    min-height: 1rem;
    padding: 5px 10px;
    border-bottom: 1px solid #eee;
}

.shared-feed-header .feeder-name {
    font-size: 14px;
    font-weight: 600;
}

.shared-feed-header .feeder-reason {
    font-size: 14px;
    color: #888;
}

.shared-feed-core {
    padding: 8px 10px;
    min-height: 1rem;
}

.shared-feed-actor {
    display: flex;
    min-height: 1rem;
}

.shared-feed-actor .actor-name {
    font-size: 13px;
    font-weight: bolder;
}

.shared-feed-actor .actor-title {
    font-size: 13px;
    color: #666;
}

.shared-feed-actor .actor-age {
    font-size: 11px;
    color: #666;
}

.feed-embedded-video {
    width: 100%;
    height: auto;
}

.shared-feed-description {
    margin: 5px;
}

.shared-feed-content {
    margin: 5px;
}

.shared-feed-content .card-table-one {
    overflow: auto;
}

.feed-text {
    margin: 10px 0;
    color: #000C;
}

.sharing-text {
    border-bottom: 1px solid #e0e0e0;
}

.resume-wrapper {
    position: relative;
}

.resume-wrapper>.feed-text {
    max-height: 4rem;
    overflow: hidden;
}

.shared-feed-activity {}

button.read-more {
    color: #aaa;
    background-color: #FFF;
    position: absolute;
    bottom: 0;
    right: 0;
    border: none;
    padding-right: 1rem;
    padding-left: 1rem;
}

button.read-more:hover {
    color: var(--hg-color-1);
    text-decoration: underline;
    cursor: pointer;
}

.shared-feed-activity-counts {
    padding: 3px 16px;
    display: flex;
    justify-content: space-between;
}

.shared-feed-activity-counts i {
    margin: 0 5px 0 0;
    font-size: 12px;
}

.shared-feed-activity-counts span {
    color: #0008;
    font-size: 12px;
}

.shared-feed-activity-counts i.fa-thumbs-up {
    color: var(--fg-color-2);
}

.shared-feed-activity-actions {
    border-top: 1px solid #eee;
    padding: 10px;
    display: flex;
    justify-content: start;
    color: #000A;
}

.shared-feed-activity-actions>div {
    border-radius: 4px;
    cursor: pointer;
    margin: 2px 5px;
    font-size: 1em;
    color: #000A;
    padding: 5px;
    font-weight: 500;
}

.shared-feed-activity-actions>div:hover {
    background-color: #ddd;
}

.shared-feed-activity-actions .active {
    color: #0a66c2;
}

.shared-feed-activity-actions>div i {
    margin-right: 5px;
}

.shared-feed-activity-comment {
    display: flex;
    border-top: 1px solid #ddd;
    padding-top: 10px;
    padding-left: 10px;
}

.shared-feed-activity-comment .comment-content {
    flex: 1 1;
    background: #eee;
    margin: 0 5px 10px;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 0.9em;
}

.comment-content-head {
    display: flex;
    justify-content: space-between;
    max-height: 32px;
}

.comment-content-text {
    margin: 5px 0;
}

.shared-feed-activity-collab {
    border-top: 1p solid #eee;
    padding: 15px;
}

.shared-feed-activity-collab .new-collab {
    display: flex;
    align-items: flex-start;
}

.new-collab .quill {
    flex: 1 1;
}

.ql-toolbar.ql-snow {
    padding: 4px !important;
}

.ql-toolbar.ql-snow .ql-formats {
    margin-top: 0px !important;
    margin-right: 0px !important;
}

.push-comment-btn {
    width: fit-content;
    padding: 5px 10px;
    background: var(--fg-color-2);
    border-radius: 50px;
    margin: 10px 0 10px auto;
    color: #FFF;
    font-weight: bold;
    cursor: pointer;
}


/**Post Edition**/

.pe-outer {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: #0008;
    overflow: auto;
}

.pe-inner {
    position: relative;
    width: 550px;
    max-width: 99vw;
    box-shadow: 0 0 15px #ddd;
    border-radius: 5px;
    background-color: #FFF;
    margin-top: 80px;
    margin-bottom: 80px;
}

.pe-close-btn {
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 20px;
    padding: 3px;
    cursor: pointer;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    box-sizing: content-box;
    line-height: 25px;
    text-align: center;
    color: #555;
}

.pe-close-btn:hover {
    color: #002;
    background-color: #ddd;
}

.pe-header {
    border-bottom: 1px solid #eee;
    padding: 5px 20px;
    margin-bottom: 10px;
}

.pe-body {
    padding: 10px;
    margin: 10px;
    min-height: 100px;
    /*  max-height: calc(100vh - 150px); 
    overflow: auto; */
}

.pe-body .quill {
    /* max-height: 220px; */
}

.h-file-input {
    font-size: 1;
    height: 1px;
    width: 1px;
    display: block;
    opacity: 0;
}

.photo-container {
    padding: 5px 0;
    margin: 5px 0;
    max-width: 600px;
    position: relative;
}

.photo-container .remove-btn,
.attachment-block .remove-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #FFF8;
    background: #8888;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    font-size: 14px;
}

.photo-container .remove-btn:hover,
.attachment-block .remove-btn:hover {
    color: #FFF;
    background: #000;
}

.photo-container img {
    display: block;
    max-width: 100%;
    margin: auto;
    border: 1px solid #ccc;
}

.ql-bubble .ql-tooltip {
    z-index: 100px;
}

.vsep {
    width: 1px;
    height: 36px;
    margin: 0 10px;
    background-color: #aaa;
    box-shadow: 0 0 4px #aaa;
}

.attachment-block {
    position: relative;
    padding: 10px;
    margin: 10px 0;
    box-shadow: 0 0 8px #ccc;
}

.attachment-block a i {
    font-size: 18px;
    margin-right: 5px;
}

.attachment-block a span {
    font-size: 16px;
    color: #0f0f1e;
    font-weight: 600;
}

.pe-inner .channel-select {
    max-width: 600px;
    padding: 5px;
    margin: 10px;
}

.loading {
    margin: 40px auto;
    width: 100%;
    text-align: center;
    font-size: 80px;
}

.feed-column .box-card,
.tendence-column>.box-card {
    margin-bottom: 1rem;
}

.tendence-column>.box-card {
    border-left: 1px solid #35E3D9;
}

.tendence-column>.box-card:nth-child(3n+1) {
    border-left: 1px solid #35ACE3;
}

.tendence-column>.box-card:nth-child(3n+2) {
    border-left: 1px solid #8575AC;
}

.tendence-column .box-card {
    /* zoom: 0.9;*/
    max-width: 600px;
}

@media (min-width: 992px) {
    .feed-column {
        margin: 0 20px 0 0;
    }
    .control-column .box-card-show-btn {
        display: none;
    }
    .control-column.disabled .box-card-show-btn {
        display: none;
    }
    .share-box-feed-entry-toolbar .vsep {
        display: block;
    }
    .control-column .channel-card.box-card {
        display: block;
        margin-bottom: 2rem;
        padding: 0;
    }
    .control-column .channel-card.box-card .box-ctrl-btns {
        display: none;
    }
    .fixed .control-column.control-right {
        position: fixed;
        top: 90px;
        left: 800px;
    }
    .control-column.control-left {
        display: none;
    }
    .az-header-menu-show .fixed .control-column.control-right {
        left: 960px;
    }
}

@media (min-width: 1200px) {
    .tendence-column {
        display: block;
    }
    .time-line {
        padding-right: 480px;
    }
    .feed-column {
        padding: 20px;
    }
}

@media (max-width: 580px) {
    .list-responsive {
        zoom: 0.8;
    }
    .order-by-block {
        display: block;
    }
}

@media (min-width: 1400px) {
    .feed-column,
    .fixed .feed-column,
    .az-header-menu-show .fixed .feed-column {
        margin-right: 20px;
        margin-left: 0px;
    }
    .tendence-column {
        display: block;
    }
    .time-line {
        padding-right: 480px;
    }
    .control-column.control-left {
        display: block;
    }
    .fixed .control-column.control-right {
        position: fixed;
        top: 90px;
        right: calc(50% - 610px);
        left: initial;
    }
    .az-header-menu-show .fixed .control-column.control-right {
        right: calc(50% - 720px);
        left: initial;
    }
    .fixed .control-column.control-left {
        position: fixed;
        top: 90px;
        left: calc(50% - 550px);
    }
    .az-header-menu-show .fixed .control-column.control-left {
        left: calc(50% - 440px);
    }
}

.activable-link.active,
.activable-link.active i {
    color: var(--fg-color-1);
}

.fa.fa-newspaper.for-all {
    color: green;
}

@media (min-width: 1600px) {
    .time-line {
        padding-right: 580px;
    }
    .tendence-column {
        width: 580px;
    }
}

@media (min-width: 1800px) {
    .time-line {
        padding-right: 680px;
    }
    .tendence-column {
        width: 680px;
    }
}

@media (min-width: 1900px) {
    .time-line {
        padding-right: 730px;
    }
    .tendence-column {
        width: 730px;
    }
    .az-iconbar {
        width: 300px !important;
    }
    .az-content-dashboard-three {
        margin-left: 300px !important;
    }
}

@media (min-width: 2400px) {
    .time-line {
        padding-right: 980px;
    }
    .tendence-column {
        width: 980px;
    }
}

@media (min-width: 3000px) {
    .time-line {
        padding-right: 1280px;
    }
    .tendence-column {
        width: 1280px;
    }
}