.az-dashboard-nav .nav .btn-group {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    margin-bottom: -1px;
}

@media (max-width: 768px) {
    .az-dashboard-nav .nav .btn-group {
        display: none;
    }
}

.az-dashboard-nav .nav .btn-group .btn {
    font-size: 12px;
    font-weight: 500;
    padding: 5px 10px;
    min-height: inherit;
    background-color: #fff;
    color: #7987a1;
    border: 1px solid #cdd4e0;
}

.az-dashboard-nav .nav .btn-group .btn.active {
    color: var(--hg-color-1);
    background-color: #e3e7ed;
    border-color: #cdd4e0;
}

.action-btns-droplist .btn i {
    margin-right: 10px;
}

.target-entity {
    position: relative;
}

.target-entity h6 {
    cursor: pointer;
}

.target-entity h6 i {
    margin-left: 5px;
}

.entities-list.drop-down-menu {
    padding: 5px 5px 2px 5px;
    position: absolute;
    top: 50px;
    left: auto;
    bottom: auto;
    border-color: #cdd4e0;
    border-width: 2px;
    background-color: #fff;
    z-index: 200;
    border: 1px solid #ccc;
    width: 200px;
}

.entities-list.drop-down-menu ul {
    padding: 0 5px;
}

.entities-list.drop-down-menu li {
    list-style: none;
    margin-bottom: 5px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    padding: 3px;
    font-size: 13px;
    color: #444;
}

.entities-list.drop-down-menu li:hover {
    font-weight: bold;
    color: var(--fg-color-2)A0
}

.entities-list.drop-down-menu ul li:last-child {
    border-bottom: none;
    margin-bottom: 0px;
}

.kpi-navigator {
    display: flex;
}

.kpi-navigator.overall-mode>.card {
    flex: 1 1 100%;
}

.kpi-navigator.overall-mode>.card .kpi-box {
    flex: 0 0 300px;
    padding-right: 15px;
    padding-left: 15px;
    height: 100%;
}

.kpi-navigator.drill-mode>.card {
    flex: 0 0 300px;
    max-height: calc(100vh - 220px);
    overflow: auto;
}

.kpi-navigator.drill-mode>.card .kpi-box {
    flex: 1 1 100%;
}

.kpi-navigator.drill-mode>.drilled-kpi {
    flex: 1 1;
    margin: 0 1rem;
}

.entity-reset {
    text-align: center;
    padding: 10px;
    cursor: pointer;
}

a.ind-tab-title.nav-link {
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    color: #888;
}

a.ind-tab-title.nav-link.active {
    font-weight: bold;
}

.drilled-kpi-toolbar {
    position: relative;
    z-index: 50;
}

.drilled-kpi-toolbar>span {
    margin: 5px 0px;
    padding: 2px 10px;
    cursor: pointer;
    border-radius: 1px;
    font-size: 18px;
    color: #666;
    border: 1px solid #ccc;
    position: absolute;
    top: 0;
    right: 5px;
}

.drilled-kpi-toolbar>span:nth-child(2) {
    right: 44px
}

.drilled-kpi-toolbar>span:nth-child(3) {
    right: 83px
}

.drilled-kpi-toolbar>span:nth-child(4) {
    right: 122px
}

.drilled-kpi-toolbar>span:nth-child(30) {
    right: 119px
}

.drilled-kpi-toolbar>span:hover {
    background-color: #eee;
}

.drilled-kpi-toolbar>span.active {
    background: #e3e7ed;
}

.btn-group-parent {
    position: relative;
}

.period-control-panel {
    position: absolute;
    top: 100%;
    z-index: 400;
    left: 0;
    padding: 10px;
    right: 0;
    background: #e3e7ed;
    border: 1px solid #ccd;
}

.period-control-panel .react-datepicker__input-container>input {
    width: 100%;
}

span.timespan {
    padding: 0px 10px;
    color: #aab;
    font-weight: 600;
    font-size: 0.9em;
    line-height: 30px;
}

.rendererToggle {
    z-index: 30;
    cursor: pointer;
    top: 5px;
    right: 5px;
    background: #e3e7ed;
    width: 30px;
    height: 30px;
    border-radius: 0px;
    text-align: center;
    line-height: 29px;
    border: #ccc 1px solid;
    padding: 0;
    font-size: 18px;
}

.kpi-row {
    width: 100%;
    overflow: auto;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: nowrap;
    min-width: 100%;
}

.kpi-row .kpi-chart {
    width: 100%;
    height: 430px;
}

.kpi-resume {
    margin-right: 1rem;
    flex: 0 0 400px;
    max-width: 90vw;
}

.kpi-details {
    flex: 1 1;
    overflow-x: auto;
    overflow-y: hidden;
    min-width: 100vw;
}

.kpi-board {
    margin: 4px;
}

.kpi-details h6.card-title {
    max-width: calc(100% - 145px);
    color: var(--hg-color-1);
    font-size: 18px;
    font-weight: 800;
    font-family: 'MONTSERRAT';
}

@media (min-width: 550px) {
    .kpi-details {
        min-width: 550px;
    }
}

@media (max-width: 992px) {
    .VictoryContainer {
        touch-action: initial !important;
    }
}

@media (min-width: 992px) {
    .kpi-board {
        margin: 20px;
    }
    .kpi-row {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .kpi-row .kpi-chart {
        width: 100%;
        height: 452px;
        overflow: auto;
    }
}