@media (min-width:768px) {
    .coefficient-block {
        border: 1px solid #ccc;
        padding: 10px 20px;
        justify-content: space-between;
        display: flex;
    }
}

.challenges {
    margin: 5px 10px;
}

.coefficient-block-left {
    flex: 1 1;
}

.coef-block {
    justify-content: space-between;
    display: flex;
}

.coef-block .form-group {
    flex: 1 1;
    margin-right: 5px;
}

.coef-block .form-group:first-child {
    margin-right: 15px;
}

.unit-badge {
    background: #eee;
    padding: 0 10px;
    line-height: 38px;
    border: 1px solid #aaa;
    box-sizing: border-box;
    display: inline-block;
    height: 38px;
    border-left: none;
    font-weight: bold;
}

@media (max-width:767px) {
    .unit-badge {
        display: none;
    }
}

.coef-block span i {
    font-size: 20px;
    margin-right: 5px;
    cursor: pointer;
    color: var(--fg-color-1);
    line-height: 38px;
}

.graph-function-box {
    height: 300px;
    width: 330px;
}

@media (min-width: 992px) {
    .graph-function-box {
        width: 500px;
    }
}

.rule-file-fram {
    border: none;
    margin: 20px 0;
    width: 100%;
    min-height: 600px;
}

.charllenge-results {
    margin-top: 0px;
    border: none;
}

.collapsed .hidable-panel-body {
    display: none;
}

.collapsed .block-header {
    cursor: pointer;
}

.challenge-display-block-container {
    display: flex;
}

.challenge-display-block-container hr {
    flex: 1 1;
}

.disp-md-label {
    font-size: 12px;
    font-weight: bold;
    padding: 0 1rem;
    line-height: 28px;
}

.disp-md {
    font-size: 12px;
    font-weight: bold;
    margin-left: 1rem;
    cursor: pointer;
    line-height: 28px;
}

.disp-md i {
    font-size: 14px;
    font-weight: bold;
    margin-right: 0.5rem;
    color: #ccc;
}

.disp-md i.fa-circle {
    color: #EE3C25;
    font-size: 8px;
    margin-right: 0.25rem;
    vertical-align: baseline;
    position: relative;
    top: -2px;
}

.tp10-title {
    color: var(--fg-color-1);
    font-size: 15px;
    font-weight: 700;
}

.tp10-date {
    color: #666;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 1rem;
}

.list-row-tp10 {
    display: flex;
    margin-top: 8px;
    height: 40px;
    align-items: center;
}

.top10 {
    display: flex;
    margin-top: 8px;
    justify-content: space-between;
}

.tp10-cell {
    margin-right: 1rem;
}

.top-cell-rank {
    flex: 0 0 40px;
    font-size: 18px;
    font-weight: bold;
}

.rankieme {
    position: relative;
    top: -7px;
    font-size: 10px;
    left: 2px;
}

.top-cell-collab {
    display: flex;
    justify-content: start;
    flex: 1 1;
    align-items: center;
}

.top-cell-collab span.collab-name {
    line-height: 13px;
}

.top-cell-collab .avatar-initials {
    font-size: 13px;
    font-weight: 700;
    padding: 5px;
    border-radius: 87%;
    color: #FFF;
    width: 30px;
    display: block;
    height: 30px;
    box-sizing: border-box;
    text-align: center;
}

.top-cell-collab .share-box-feed-img-user {
    width: 45px;
    height: 42px;
    flex: 0 0 45px;
    padding: 5px;
    display: block;
    position: relative;
    border-radius: 100%;
}

.top-cell-point {
    flex: 0 0 80px;
    line-height: 32px;
}

.top-cell-rewar {
    flex: 0 0 80px;
    line-height: 32px;
    margin-right: 0px;
}

.list-row {
    display: flex;
    margin-bottom: 0.5rem;
    padding: 5px;
    padding-left: 1rem;
}

.list-row:hover {
    background-color: #f0f0f0;
}

.list-row .col-sep {
    border-left: 1px solid #ccc;
    margin: 2px 1rem;
}

.colA {
    flex: 0 0 100px;
    font-size: 12px;
}

.colB {
    font-size: 12px;
    margin-right: 1rem;
}

.colA .lbl,
.colB .lbl {
    color: #bbb;
    font-weight: 500;
}

.colA .val {
    font-weight: 600;
}

.colB .vals {
    display: flex;
}

.colB .vals .g1,
.colB .vals .g2 {
    min-width: 100px;
}

.colB .val1,
.colB .val2 {
    padding: 4px 8px;
    background: #eee;
    margin: 0 8px;
    border-radius: 13px;
    font-weight: 600;
}

.colB .lbl1,
.colB .lbl2 {
    font-size: 12px;
    font-weight: 500;
}

.card-title-container {
    flex: 1 1;
}

.no-ready {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80vh;
}

.no-ready i {
    font-size: 100px;
}