.stop-watch {
    background-color: #202040;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 10px;
}

@media (min-width: 992px) {
    .stop-watch {
        margin: 10px;
    }
}

.stop-watch i {
    color: #f5f5f5;
    margin: auto 10px auto 5px;
    cursor: pointer;
    font-size: 20px;
}

.timer {
    margin: 5px 15px;
    width: 100%;
    display: flex;
    height: 12%;
    justify-content: center;
    align-items: center;
}

.digits {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 1.2rem;
    color: #f5f5f5;
}

.mili-sec {
    color: #e42a2a;
}