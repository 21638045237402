.popup-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: #ddda;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-inner {
    box-shadow: 0 0 20px #222;
    background-color: #FFF;
    position: relative;
    padding: 10px 20px;
    max-width: 90vw;
    overflow: auto;
    max-height: 95%;
}

.popup-inner-large {
    width: 700px;
}

.popup-inner>i.fa {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: 25px;
    height: 25px;
    background: #eee;
    text-align: center;
    line-height: 25px;
    border-radius: 25px;
    opacity: 0.7;
}

.popup-inner>i.fa:hover {
    background: #ccc;
    opacity: 1;
}

.fieldset {
    border: 1px solid #ddd;
    padding: 10px 20px;
    margin: 10px;
}

.fieldset h5,
.fieldset h6,
.fieldset h4 {
    color: var(--hg-color-1);
    font-weight: 700;
    border-bottom: 1px solid #dde;
    padding-bottom: 5px;
}

.popup-header {
    font-weight: 700;
    padding: 5px 0px;
    color: var(--fg-color-1);
}

.btn.generate-template i.fa {
    margin-right: 10px;
}

.btn.generate-template {
    text-align: center;
    width: -moz-fit-content;
    width: fit-content;
    background: var(--fg-color-1);
    opacity: 0.85;
    color: #fff;
    display: block;
    font-weight: bold;
}

.btn.generate-template:hover {
    opacity: 1;
}

.generate-template.btn-confirm {
    background: #FFF;
    color: var(--fg-color-1);
    border: 2px solid;
    font-weight: bold;
}

.generate-template.btn-confirm:hover {
    color: #FFF;
    background: var(--fg-color-1);
    border: 2px solid;
    font-weight: bold;
}

.flex-sa {
    display: flex;
    justify-content: space-between;
}

.flex-sa label {
    margin-bottom: 0;
}

.flex-sa .file-name {
    line-height: 40px;
    font-size: 13px;
    font-weight: 600;
}

.flex-c2,
.flex-c3 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.flex-c2>div,
.flex-c2>input {
    flex: 0 0 95%;
}

.flex-c3>div {
    flex: 0 0 95%;
}

@media (min-width:768px) {
    .flex-c2>div,
    .flex-c2>input {
        flex: 0 0 48%;
    }
    .flex-c3>div {
        flex: 0 0 32%;
    }
}

.popup-inner .loading {
    position: absolute;
    z-index: 1002;
    width: 100%;
    height: 100%;
    background-color: #8885;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}