@media (min-width: 768px) {
    .Training {
        margin: 10px;
        margin-bottom: 40px;
        box-shadow: 0 0 20px #bbb;
        border-radius: 5px;
    }
    .trn-info {
        display: flex;
    }
    .stg-body {
        padding: 5px 1rem;
    }
    .mdl-img {
        border-right: 1px solid #ccc;
    }
}

.Training {
    margin: 20px;
}

.trn-show-detail-btn {
    text-align: right;
    padding: 10px 50px;
}

.trn-show-detail-btn .btn {
    margin-left: 1rem;
}

.trn-show-detail-btn i {
    margin: 0 8px;
}

.Training .media {
    padding-bottom: 5px;
}

.trn-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
}

.trn-info {
    border-top: 1px solid #ccc8;
    padding: 10px;
}

.trn-image {
    flex: 0 0 200px;
    padding: 10px;
}

.trn-image img {
    display: block;
    width: 100%;
    height: auto;
}

.trn-right {
    flex: 1 1;
}

.trn-description {
    padding: 10px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
}

@media (min-width: 768px) {
    .trn-description {
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .trn-metric {
        flex: 0 0 250px;
    }
}

.trn-metric {
    padding: 10px 5px 5px 0;
}

.trn-description p {
    margin-bottom: 2px;
}

.trn-desc-cont {
    flex: 1 1;
}

.trn-me {
    color: #666;
    text-align: right;
    margin-right: 5px;
}

.trn-me i {
    color: var(--fg-color-2);
    margin: 2px 2px 0 8px;
}

.trn-me-statut {
    padding: 2px 6px;
    background: #0808;
    color: white;
    border-radius: 4px;
    font-size: 0.9em;
    margin-right: 4px;
    margin-left: 8px;
}

.trn-me-STARTED {
    background-color: orange;
}

.trn-me-CLOSED {
    background-color: #f88;
}

.StagePanel {
    margin: 10px 0px;
    box-shadow: 0 0 10px #ccc;
    border-radius: 4px;
}

.StagePanel:hover {
    background: #F8F8F8;
    border: 1px solid #CCE;
}

.StagePanel i {
    cursor: pointer;
}

.stg-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.stg-img {
    flex: 0 0 60px;
    padding: 15px 10px;
}

.stg-img img {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50px;
}

.stg-title {
    flex: 1 1;
    font-size: 17px;
    font-weight: 500;
    padding: 10px;
    color: #333;
    display: flex;
    align-items: center;
    min-width: 200px;
}

.stg-stt {
    flex: 0 0;
    font-size: 18px;
    font-weight: 500;
    padding: 5px 10px;
    line-height: 48px;
}

.stg-collapse {
    flex: 0 0 50px;
    font-size: 18px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stg-collapse i {
    color: var(--hg-color-1);
}

.stg-body {
    border-top: 1px solid #ccc;
    padding: 5px;
}

.ModulePanel {
    margin: 10px 5px;
    border: solid 1px #ccc;
    background: #eee8;
}

.ModulePanel:hover {
    background: #eee;
    border: 1px solid #bbb;
}

.mdl-img {
    flex: 0 0 50px;
    padding: 5px 10px;
}

.mdl-img img {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50px;
}

.mdl-media {
    margin-top: 10px;
    margin-bottom: 20px;
}

.mdl-media iframe {
    border: none;
    width: 100%;
    margin: auto;
    height: 70vh;
    display: block;
}

.stage-block {
    box-shadow: 0 0 7px #ddd;
    padding: 10px 20px;
    margin: 10px 0 15px;
}

.module-block {
    border: 1px solid #ddd;
    padding: 10px 20px;
    margin: 10px 0 15px;
    background-color: #F4F4F6;
}

.module-block .ql-container {
    background-color: #FFF;
}

.tex-btn {
    padding: 5px 16px;
    margin-left: 10px;
    color: #FFF;
    background: var(--fg-color-2);
    cursor: pointer;
    border: 1px solid;
    border-radius: 18px;
}

.tex-btn.bold {
    font-weight: bold;
}

.tex-btn.neg {
    background-color: #FFF;
    color: var(--fg-color-2);
}

.tex-btn.neg:hover {
    color: #FFF;
    background: var(--fg-color-2);
}

.tex-btn i {
    margin-right: 10px;
}

.media-resource {
    margin-top: 28px;
}

.noml .tex-btn {
    margin-left: 0;
    margin-right: 20px;
}

.config-form {
    padding: 1rem;
}