.tabs-block {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    border-bottom: 1px solid #ccc;
    margin: 5px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
}

.tabs-block .tab {
    padding: 0.5rem 1rem;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.tabs-block .tab:hover {
    background-color: #eee;
    font-weight: 700;
}

.tabs-block .tab.selected {
    font-weight: 700;
    border: 1px solid #ccc;
    position: relative;
    top: 1px;
    background-color: #FFF;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

@media (min-width: 768px) {
    .tabs-block .tab.selected {
        border-bottom: none;
    }
    .tabs-block .tab {
        font-size: 16px;
    }
}