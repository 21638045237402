.assessment {
    padding: 20px;
}

@media (min-width: 768) {
    .assessment {
        padding: 1rem 2rem;
    }
}

.assmt-title {
    text-transform: uppercase;
}

.assmt-scene {
    margin-top: 1rem;
    box-shadow: 0 0 25px #ddd;
}

.assmt-question {
    border: 1px solid #ddd;
    display: flex;
}

.assmt-quesion-body {
    flex: 1 1;
    padding: 20px;
}

.assmt-question .assmt-progress {
    flex: 0 0 40px;
    border-right: 1px solid #ddd;
}

.assmt-progress-outer {
    min-height: 300px;
    margin: 20px auto;
}

@media (min-width: 768px) {
    .assmt-question .assmt-progress {
        flex: 0 0 100px;
    }
    .assmt-progress-lbl {
        font-size: 19px;
    }
}

.assmt-progress-middle {
    height: 280px;
    width: 10px;
    border-radius: 5px;
    background-color: #aaa;
    margin: 10px auto;
}

.assmt-progress-inner {
    width: 10px;
    border-radius: 5px;
    background-color: var(--fg-color-2);
}

.assmt-progress-lbl {
    position: relative;
    font-weight: bold;
    text-align: center;
    color: var(--fg-color-2);
}

.assmt-nav {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    border: 1px solid #ddd;
    border-top: none;
}

.assmt-nav-btn button {
    display: block;
    background-color: var(--fg-color-2);
    padding: 7px 14px;
    line-height: 1.573;
    color: #fff;
    border: none;
    font-size: 14px;
    font-weight: 500;
}

@media (min-width: 768px) {
    .assmt-nav-btn button {
        padding: 9px 25px;
        font-size: 17px;
    }
}

.assmt-nav-btn-next {}

.assmt-nav-btn-finish button {
    background-color: #109c3f;
}

.assmt-nav-btn-verify button {
    background-color: var(--bg-color-2);
    color: var(--fg-color-2);
    text-decoration: underline;
    font-weight: 700;
}

.answer-comment {
    font-size: 1rem;
    color: #FFF;
    background-color: #109c3f88;
    border-radius: 3px;
    padding: 1rem 1.6rem;
    margin-right: 40px;
}

.assmt-bravo {
    padding: 40px;
    text-align: center;
    /* background: #eee; */
    width: 523px;
    margin: 40px auto;
    /* border-radius: 15px; */
    color: var(--fg-color-2);
    /* box-shadow: 0 0 30px #ddd; */
    font-size: 34px;
    font-weight: 700;
}

li.assmt-option {
    list-style: none;
    cursor: pointer;
    font-size: 16px;
    line-height: 32px;
}

li.assmt-option i {
    font-size: 1.1em;
    margin-right: 10px;
    color: var(--fg-color-2);
}

li.assmt-option i.correct-option {
    color: #FFF;
    background-color: #7fc998;
    border-radius: 5px;
    padding: 7px;
    font-size: 16px;
    margin-left: 1rem;
}

li.assmt-option i.error-option {
    color: #FFF;
    background-color: red;
    border-radius: 5px;
    padding: 7px;
    font-size: 16px;
    margin-left: 1rem;
}

.assmt-freetext {
    padding: 5px 40px;
}

.assmt-freetext textarea {
    resize: none;
    width: 100%;
    background: #F0F0F080;
    border-radius: 4px;
    padding: 1rem;
    color: #444;
    border: 1px solid var(--fg-color-2)80;
}

.assmt-freetext textarea:focus {
    border: 2px solid var(--fg-color-2);
    outline: none !important;
}

@media (min-width:768px) {
    .assmt-header {
        display: flex;
        justify-content: space-between;
    }
    .question-block-header {
        display: flex;
        justify-content: space-between;
    }
}

.question-block-header i {
    color: orangered;
    font-size: 16px;
    margin-left: 10px;
}

options-block {}

.option-block {
    margin-bottom: 5px;
    padding: 0 10px
}

.option-id {
    margin-right: 5px;
}

.option-block i {
    color: #109c3f;
    margin-left: 15px;
    font-size: 18px;
}

p.txt-disp-block {
    background: #e1e6ec;
    padding: 5px 10px
}

p.txt-disp-block:empty {
    display: none;
}

.ass-det {
    font-size: 16px;
    font-weight: bold;
    margin: 2px 10px;
    padding: 2px;
    text-align: center;
}

.ass-det label {
    color: var(--fg-color-2);
    margin-bottom: 1px;
}

.app-form.b1c {
    display: block;
}

.ass-det strong {
    color: #225;
}

.assessment-info-cell .btn {
    font-family: 'Roboto';
    text-transform: uppercase;
    font-weight: bold;
    margin: 5px 10px;
    cursor: pointer;
}

.assessment-info-cell .btn i {
    margin-right: 10px;
    margin-left: 10px;
}

.assessment-info-cell .btn:hover {
    background: var(--fg-color-1);
    color: #FFF;
}

.assessment-info-cell {
    box-shadow: 0 0 15px #ddd;
    padding: 10px 20px;
    margin: 16px 0;
    text-align: center;
}

@media (min-width: 768px) {
    .assessment-info-resume {
        display: flex;
        justify-content: space-between;
        margin: 2rem 0;
    }
    .assessment-info-cell {
        margin: 0
    }
}

.question-type {
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}

.app-form .form-group.cquart {
    max-width: 18%;
}

.app-form label {
    font-weight: 600;
}

.ass-def-result {
    padding: 2rem;
    width: 100%;
}

.ass-intro {
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0;
    padding: 20px;
    box-shadow: 0 0 10px #ddd;
}

.ass-intro-text,
.ass-intro-img {
    flex: 0 0 48%;
}

.ass-intro-img img {
    width: 100%;
}

.ass-result-tab {
    padding: 1rem 2rem;
    box-shadow: 0 0 15px #ddd;
    overflow: auto;
}

i.ok {
    color: green;
    font-size: 20px;
}

i.ko {
    color: red;
    font-size: 20px;
}

i.review.fa {
    color: var(--fg-color-2);
    font-size: 20px;
    cursor: pointer;
    margin: auto 1rem;
}

.ass-stat-chart-container {
    width: 100%;
    border: 1px solid #ddd;
    padding: 5px;
    margin: 5px 0 20px;
}

.table-stat {
    margin: 5px 0 20px;
}

.table-stat-block {
    width: 100%;
    border: 1px solid #eee;
    padding: 10px 20px;
    margin: 0;
}

.table-stat-block:nth-child(even) {
    background-color: #F8F8F8;
}

.table-stat-block .qh {
    display: flex;
    justify-content: space-between;
}

.table-stat-block .qb {
    font-weight: bold;
}

.table-stat-block .qn {
    padding: 0;
}

.table-stat-block .ql {
    padding: 0 5px;
}

.table-stat-block .qv {
    padding: 0 10px;
    font-weight: bold;
}

.table-stat-block .qs {
    display: flex;
    justify-content: space-between;
}

.table-stat-block .qsi {
    padding: 4px 10px;
    background-color: #202040;
    color: #FFF;
    font-weight: bold;
    font-size: 0.8em;
    margin-left: 10px;
    border-radius: 5px;
}

.table-stat-block .qsi span {
    font-weight: normal;
    opacity: 0.8;
    margin-right: 5px;
}

.table-stat-block .qsi.green {
    background-color: #4caf50;
}

.table-stat-block .qsi.red {
    background-color: red;
}

.ass-def-stat {
    width: 100%;
}

.op-delete {
    cursor: pointer;
    color: red;
    margin-left: 10px;
    margin-top: 4px;
}

.rating-config-block,
.rating-block {
    display: flex;
    justify-content: space-between;
}

.rating-block {
    margin: 5px 20px;
    max-width: 600px;
    margin-bottom: 20px;
}

.rating-outer {
    position: relative;
    background-color: #ddd;
    height: 10px;
    border-radius: 3px;
    cursor: pointer;
    flex: 1 1;
    margin: auto 10px;
}

.rating-inner {
    background-color: var(--fg-color-1);
    height: 10px;
    border-radius: 3px;
    width: 50%;
}

.rating-outer .rating-value {
    position: absolute;
    background: var(--fg-color-1);
    color: #FFF;
    padding: 5px 10px;
    margin-top: 5px;
    transform: translate(-50%, 0px);
    border-radius: 3px;
}

.rating-outer .rating-cursor {
    left: 0px;
    position: absolute;
    width: 2px;
    height: 20px;
    background: var(--fg-color-1);
    top: -5px;
}

.rating-min,
.rating-max {
    font-size: 20px;
    font-weight: bold;
}