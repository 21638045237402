.paging-bar {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    border-top: 1px solid #ddd;
    color: var(--hg-color-1);
}

.paging-bar .left i {
    width: 25px;
    cursor: pointer;
    background-color: var(--fg-color-2);
    color: #FFF;
    text-align: center;
    margin: 5px;
    height: 20px;
    line-height: 20px;
    border-radius: 3px;
}

.paging-bar .left span {
    margin: 5px;
}

.paging-bar .left input {
    width: 50px;
    margin: 5px;
}

@media (max-width:768px) {
    .page-from-to {
        display: none;
    }
}