td.action-btn a {
    margin-left: 10px;
    margin-right: 5px;
    font-size: 20px;
    line-height: 20px;
}

p.form-error {
    background-color: orange;
    color: #FFF;
    padding: 10px 30px;
    font-size: 16px;
    margin-top: 28px;
}

.search-console {
    display: flex;
    justify-content: space-between;
}

.criteria {
    flex: 1 1;
    margin-right: 50px;
}

.criteria-control {
    position: relative;
}

@media (max-width: 768px) {
    .criteria-control {
        display: none;
    }
}

.criteria-control i {
    position: absolute;
    top: 10px;
    right: 10px;
}

.actions-toolbar i {
    margin-right: 5px;
}

.app-form {
    margin: 20px 0px;
    padding: 1rem 1rem;
    box-shadow: 0 0 15px #ddd;
}

@media (min-wisth:768px) {
    .app-form {
        margin: 20px 30px;
        padding: 1rem 2rem;
        box-shadow: 0 0 15px #ddd;
    }
}

.app-form .col-left {
    flex: 1 1;
    max-width: 100%;
}

.app-form .col-right {
    flex: 1 1;
}

.app-form .form-group {
    max-width: 550px;
}

.pwd-bnt-pan {
    padding: 2rem;
    text-align: center;
}

.reconnect-prompt {
    margin: 15px 0;
}

.form-group-line {
    display: flex;
    justify-content: space-between;
}

.form-group-line .col-6 {
    padding: 0;
    flex: 0 0 48%;
    max-width: 48%;
}

.media+.media.noextramarge {
    margin-left: 8px;
    padding-left: 0px;
}

.actions-toolbar {
    display: flex;
    justify-content: space-between;
}

.actions-toolbar .btn {
    padding-left: 9px;
    padding-right: 8px;
    margin-left: 8px !important;
    font-size: 0.85em;
    min-height: 30px;
    line-height: 30px;
    min-height: 30px;
    padding: 0 9px;
}

.actions-toolbar .btn i {
    margin-right: 8px;
    font-size: 16px;
}

.delete-btn {
    margin-top: 20px;
}

.delete-btn i {
    margin-right: 8px;
}

.user-entity-droplist .dd-lst-disp {
    border: 1px solid #cdd4e0;
    padding: 5px 10px;
}

.user-entity-droplist .dd-lst-propositions {
    left: 0;
    width: 100%;
}


/***multival-bloc***/

.multival-bloc {
    background-color: #e3e7ed;
    border: 1px solid #ddd;
    display: flex;
}

.multival-bloc-vals {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1;
}

.multival-item {
    border-radius: 5px;
    position: relative;
    background-color: #225;
    color: #FFF;
    padding: 5px 20px 5px 10px;
    margin: 5px;
}

.multival-item .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    font-size: 10px;
    opacity: 0.6;
}

.multival-item .close-btn:hover {
    opacity: 1;
}

.multival-item .multival-val {
    text-transform: capitalize;
}

.multival-item .multival-lbl {
    font-size: 0.8em;
}

.multival-bloc-bag {
    flex: 0 0 20px;
    font-size: 20px;
    position: relative;
}

.multival-bloc-bag i {
    position: absolute;
    top: -2px;
    right: -1px;
    cursor: pointer;
}

.no-item-message {
    padding: 10px 20px;
}

.multival-bloc-droplist .dd-lst-disp {
    display: none;
}

.multival-bloc-droplist .dd-lst-propositions {
    width: 100%;
    left: 0;
}


/*** Modal Screen ***/

.modal-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background-color: #20204088;
}

.modal-screen .popup {
    width: 400px;
    height: 200px;
    padding: 30px 40px;
    text-align: center;
    box-shadow: 0 0 15px #ccc;
    border-radius: 5px;
    background-color: #FFF;
    position: relative;
}

.modal-screen .popup-inner {
    padding: 5px 50px 10px 20px;
    box-shadow: 0 0 15px #ccc;
    border-radius: 5px;
    background-color: #FFF;
    position: relative;
    min-width: 30vw;
    max-width: 60vw;
}

.modal-screen .popup-inner.large {
    min-height: 50vh;
    max-width: 70vw;
    min-width: 70vw;
}

.modal-screen .popup-inner.medium {
    min-height: 50vh;
    width: 50vw;
}

.modal-screen .popup-inner.small {
    min-width: 10vw;
}

@media (max-width:768px) {
    .modal-screen .popup-inner {
        padding: 5px 25px 10px 10px;
        min-width: 50vw;
        max-width: 100vw;
    }
    .modal-screen .popup-inner.large {
        min-height: 100vh;
        max-width: 100vw;
        min-width: 100vw;
    }
    .modal-screen .popup-inner.medium {
        min-height: 100vh;
        width: 100vw;
    }
    .modal-screen .popup-inner.small {
        min-width: 50vw;
    }
}

.popup-body {
    padding: 15px;
}

.popup-footer {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.popup-footer i {
    margin: 0 10px 0 0;
}

.modal-screen .popup-inner .app-form {
    box-shadow: none;
    margin: 15px 10px;
    padding: 0;
}

.modal-screen .popup-header {
    font-size: 1rem;
    font-weight: bold;
    color: var(--fg-color-1);
}

.modal-screen .popup p {
    font-size: 16px;
    font-weight: 600;
}

.modal-screen .popup>i {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    opacity: 0.5;
    border-radius: 20px;
    padding: 4px;
}

.modal-screen .popup>i:hover {
    opacity: 1;
    background: #ccc;
}

.avatar-container {
    margin: 1rem auto;
    width: 160px;
    height: 160px;
    text-align: center;
    border: 1px solid #cdd4e0;
    padding: 5px;
    box-sizing: content-box;
    border-radius: 200px;
    overflow: hidden;
}

.avatar-container label {
    display: block;
    width: 100%;
    height: 100%;
}

.avatar-container label.canupload {
    cursor: pointer;
}

.avatar-container img {
    display: block;
    width: 100%;
    height: auto;
}

i.noavatar {
    line-height: 160px;
    font-size: 100px;
    color: #ccd;
}

i.novisual {
    line-height: 160px;
    font-size: 190px;
    color: #ccd;
}

i.nobanner {
    line-height: 100px;
    font-size: 100px;
    color: #ccd;
}

.form-group-x500 {
    max-width: 500px;
}

.client-banner-edition {
    max-width: 100%;
    max-height: 150px;
    overflow: hidden;
    padding: 5px;
    border: 1px solid #aaa9;
    background: none;
    position: relative;
}

.client-banner-edition img {
    width: 100%;
}

.client-banner-edition i {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 20px;
    background-color: #eee;
    cursor: pointer;
    text-align: center;
}

.form-group-fullwidth label {
    max-width: 100%;
}

.app-form .paragraph {
    background-color: #e3e7ed;
    border: 1px solid #cdd4e0;
    padding: 10px;
}

@media (min-width: 768px) {
    .flex-container {
        display: flex;
        position: relative;
    }
    .flex-sb {
        display: flex;
        position: relative;
        justify-content: space-between;
    }
}

.flex-child-11 {
    flex: 1 1;
}

.button-new-family {
    flex: 0 0 30px;
    position: relative;
    padding: 5px;
    background: #eee;
    text-align: center;
    margin-left: -1px;
    border-radius: 0px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    cursor: pointer;
}

.drop-down-block {
    padding: 10px;
    margin: 0;
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    z-index: 100;
    background: #F0F0F0;
    border: 1px solid #dde;
}

.button-new-family i {
    cursor: pointer;
}

.button-new-family span {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: var(--fg-color-2);
    color: #FFF;
    padding: 5px 10px;
    border-radius: 4px;
    width: 150px;
}

.button-new-family:hover span {
    display: block;
}

.btns-bar {
    text-align: center;
}

.btns-bar>div {
    background: var(--fg-color-1);
    color: #FFF;
    cursor: pointer;
    width: fit-content;
    padding: 5px 10px;
    margin: auto;
}

.kpi-form .dd-lst-disp {
    display: flex;
    border: 1px solid #cdd4e0;
    padding: 2px;
    padding-right: 8px;
    padding-left: 10px;
    background-color: #FFF;
}

.kpi-form .dd-lst-propositions {
    left: 0px;
    width: 100%;
}

.form-g2c {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

.form-g2c>div {
    flex: 0 0 95%;
}

@media (min-width:768px) {
    .form-g2c>div {
        flex: 0 0 48%;
    }
}

.family-field {
    position: relative;
}

.question-block .app-form {
    position: relative;
    display: block;
}

.question-block .app-form .toolbar {
    display: flex;
    justify-content: flex-end;
    right: 10px;
    top: 10px;
    position: absolute;
}

.question-block .app-form .toolbar .tlb-btn {
    margin-left: 10px;
    cursor: pointer;
}

.question-block .foot-btn-bar {
    margin-top: 20px;
    margin-bottom: 10px;
}

.flex-sb {
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.flex-pr {
    display: flex;
    justify-content: flex-end;
}

.flex-pr>div {
    margin-left: 10px;
    margin-right: 5px;
}

.btn-add-remove i {
    margin-left: 10px;
    cursor: pointer;
    font-size: 20px;
}

.fieldset-description {
    margin-top: 10px;
    color: #444;
}

.audit-row {
    display: flex;
}

.audit-row {
    display: flex;
    margin: 10px 20px;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    justify-content: space-between;
}

.audit-info {
    flex: 1 1;
    margin: 0 8px;
}

.audit-info-h1 {
    font-weight: normal;
}

.audit-info-h1 .audit-info-sp1 {
    font-weight: 600;
}

.audit-info-h1 .audit-info-sp2 {
    text-decoration: underline;
    color: #456;
}

.audit-info-h2 {
    font-weight: bold;
}

.audit-info-h3 {
    font-size: 12px;
}

.audit-info-r {
    font-weight: bold;
    color: #031b5e;
}

.btn.selected-dn {
    margin-left: 15px;
    margin-right: 15px;
    border: 1px solid;
    cursor: pointer;
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: bold;
    color: var(--fg-color-1);
}

.btn.selected-dn.active {
    background-color: var(--fg-color-2);
    color: #FFF;
}

div.licence-request {
    position: relative;
}

.copy-btn {
    position: absolute;
    right: 10px;
    bottom: 5px;
    cursor: pointer;
}

.btn-in-label {
    cursor: pointer;
    opacity: 0.6;
}

.btn-in-label:hover {
    opacity: 1;
}

.count_limit {
    padding: 5px;
    margin-bottom: 10px;
    font-weight: 500;
    opacity: 0.7;
    text-align: right;
    font-size: 0.9em;
}