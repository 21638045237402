.message-editor {
    padding: 20px;
}

.message-editor .attachments {
    margin: 45px;
    margin-bottom: 10px;
}

.message-dest-block .dd-lst-container {
    flex: 1 1;
    margin-bottom: 0.75rem;
}

.message-dest-block label {
    line-height: 38px;
    font-size: 16px;
    font-weight: bold;
    padding-right: 1rem;
    margin-bottom: 0;
}

.messages-browser {
    display: flex;
    padding: 16px;
    box-shadow: 0 0 15px #ddd;
}

.messages-browser .back-to-list-btn {
    margin-left: 16px;
    font-size: 20px;
    cursor: pointer;
}

@media (min-width: 768px) {
    .messages-browser .back-to-list-btn {
        display: none;
    }
}

.message-list {
    flex: 1 1;
    padding: 15px;
}

@media (max-width: 768px) {
    .messages-browser {
        padding: 5px;
        box-shadow: none;
    }
    .message-list {
        flex: 1 1;
        padding: 10px 0;
    }
}

.message-row {
    border-bottom: 1px solid #ddd;
    margin: 0;
    display: flex;
    cursor: pointer;
    position: relative;
}

.message-row .delete-message {
    position: absolute;
    right: 10px;
    bottom: 10px;
    background-color: #FFF;
    width: 30px;
    height: 30px;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    color: #888;
    border-radius: 30px;
}

.message-row:hover .delete-message {
    display: block;
}

.message-row .delete-message:hover {
    color: #222;
    border: 1px solid #444;
}

.message-actor-name {
    font-weight: bold;
}

.avatar-block {
    width: 60px;
    text-align: center;
    line-height: 60px;
    border-right: 1px solid #ddd;
    margin-right: 16px;
}

.message-info {
    flex: 1 1;
    padding: 10px;
}

.message-row.seen {
    background-color: #f0f0f0;
}

.message-row.active {
    background-color: #EF3C2560;
}

.message-row.seen .message-actor-name {
    font-weight: normal;
}

.massage-panel {
    flex: 1 1;
    margin: 10px 20px;
    border: 1px solid #ddd;
    padding: 1rem 2rem;
}

@media (max-width: 768px) {
    .massage-panel {
        position: fixed;
        top: 50px;
        right: 0;
        left: 0;
        width: 100vw;
        background: #FFF;
        box-sizing: border-box;
        margin: 0;
        height: calc(100vh - 50px);
        padding: 15px;
        overflow: auto;
    }
}

.massage-panel .attachment-block {
    box-shadow: none;
    border: 1px solid #ddd;
}

.message-header {
    display: flex;
    justify-content: space-between;
}

.message-body {
    padding: 10px 0px;
    border-top: 1px solid #eee;
}

.no-data {
    text-align: center;
    font-size: 23px;
    font-weight: 600;
    color: #bbc;
    font-family: montserrat;
}

.message-teaser {
    height: 23px;
    width: 100%;
    line-height: 20px;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.flex-xsb i {
    margin-right: 10px;
}

.block-btn {
    padding: 10px 20px;
    border: 1px solid #ccc;
    padding: 10px 20px;
    text-align: center;
    width: fit-content;
    margin-right: 0;
    margin-left: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
}

.message-block {
    padding: 5px 0;
    border-bottom: 1px solid #ddd;
}

.block-header {
    font-size: 12px;
    color: #888;
}