.card-table-one.indicator-card .pc {
    text-align: center!important;
    text-transform: capitalize;
}

.card-table-one.indicator-card .pl {
    text-align: left!important;
    text-transform: inherit;
}

.card-table-one.indicator-card .ind-row td {
    font-size: 14px;
}


/* Indicator box */

.indicator-card {
    margin-bottom: 20px;
}

.indicator-group {
    padding: 0 0px;
    margin-bottom: 20px;
}

.indicator-box1 {
    text-align: center;
}

.indicator-box1 .card-dashboard-two {
    height: 100%;
    border-radius: 0;
    border-color: #cdd4e0;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 11px #aaa;
    border-radius: 7px;
    position: relative;
    overflow: hidden;
}

.ind-share {
    position: absolute;
    top: -2px;
    right: -2px;
    font-size: 12px;
    cursor: pointer;
    display: none;
    padding: 4px 8px;
}

.ind-share i {
    background-color: var(--fg-color-1);
    color: #fff;
    margin-left: 5px;
    border-radius: 4px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
}

.ind-share-nlb,
.ind-drill {
    position: absolute;
    top: 2px;
    right: 2px;
    font-size: 16px;
    cursor: pointer;
    color: var(--fg-color-1);
    padding: 4px 8px;
    border-bottom-left-radius: 4px;
    display: none;
}

.ind-share-nlb:hover,
.ind-drill:hover {
    border-radius: 50px;
    background-color: #ddd;
}

.ind-drill {
    right: 32px;
}

.indicator-box1 .box-card {
    height: 100%;
}

.indicator-box1 .box-card:hover {
    background-color: #F8F8F8;
    border: 1px solid #ddd;
}

.indicator-box1 .box-card:hover .card-head>div {
    display: block;
}

.ind-title {
    font-size: 18px;
    font-weight: 800;
    margin: 8px;
    line-height: 100%;
    text-align: left;
    color: var(--hg-color-1);
    font-family: 'MONTSERRAT';
    text-transform: uppercase;
}

.ind-changetime {
    font-size: 12px;
    font-weight: 600;
    margin: 8px;
    line-height: 100%;
    text-align: left;
    color: #4458;
}

.ptp-danger {
    color: red;
}

.ptp-alert {
    color: orange
}

.ptp-ok {
    color: green
}

.ind-ach>span.ind-val {
    font-size: 42px;
    font-weight: 800;
}

.ind-ach>span.ind-unit {
    font-size: 16px;
    font-weight: 600;
    margin-left: 3px;
}

.ind-ach i {
    margin: 0 3px 0 1px;
}

.ind-ach small {
    font-size: 90%;
    font-weight: 500;
    display: block;
}

.ind-performer {
    font-size: 12px;
    font-weight: 500;
    opacity: 0.8;
}

.ind-time {
    font-size: 11px;
    font-weight: 500;
    margin-bottom: 10px;
    opacity: 0.7;
}

.card-body.card-body-gauge {
    padding: 10px 0 0 0;
}

.ind-foot {
    padding: 10px 50px 40px 10px
}

.ind-foot-gauge {
    padding: 10px;
    font-size: 13px;
    font-weight: bold;
    color: #445;
}

.ind-progress-outer {
    height: 10px;
    background-color: #aaa;
    border-radius: 4px;
    position: relative;
}

.ind-progress-inner-prorata {
    height: 10px;
    background-color: #eabcfc;
    opacity: 0.6;
}

.ind-progress-inner-achi {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    ;
    height: 6px;
    background-color: var(--fg-color-1);
    opacity: 0.9;
    position: relative;
    top: -8px;
}

.ind-global-traget-val {
    position: absolute;
    left: 100%;
    padding: 0px 2px;
    font-size: 11px;
    font-weight: 600;
    top: -7px;
    line-height: 100%;
}

.ind-global-achi-percent {
    position: absolute;
    left: 100%;
    padding: 0px 2px;
    font-size: 11px;
    font-weight: 600;
    top: 17px;
    transform: translate(-50%, 0);
    text-align: center;
    line-height: 100%;
    width: fit-content;
}

.kpi-box-gauge .card-body {
    position: relative;
}

.gauge-inner-html {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.timeperiod-label {
    font-size: 0.9em;
    color: #202040;
}

.popup-modal {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    background: #8888;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-modal .popup-inner {
    box-shadow: 0 0 15px #ddd;
    background-color: #FFF;
    position: relative;
}

.popup-inner .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    font-size: 10px;
    opacity: 0.6;
}

.ind-foot-gauge-2 {
    width: 100%;
    padding: 2px 15px 10px 10px;
}

.ind-foot-line {
    display: flex;
    justify-content: space-between;
}

.ind-foot-lbl {
    font-weight: 600;
    text-align: left;
}

.ind-foot-val {
    font-weight: bold;
    position: relative;
}

.ind-foot-val small {
    margin-left: 5px;
    color: var(--hg-color-1);
}

.ind-foot-val i.icon {
    position: absolute;
    padding-left: 2px;
}

.ind-foot-warn .ind-foot-val {
    font-weight: bold;
    color: red;
}

.chart-legend {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

@media (min-width: 992px) {
    .chart-legend {
        justify-content: center;
    }
}

.legend-block {
    display: flex;
    justify-content: center;
    margin: 10px 20px;
}

.legend-block div {
    margin-left: 5px;
    font-size: 13px;
    font-weight: 600
}

.ind-foot-val .fa-arrow-up,
.ind-foot-val .fa-arrow-down {
    background-color: var(--fg-color-1);
    color: white;
    margin-left: 4px;
    transform: rotate(-45deg);
    font-size: 10px;
    border-radius: 50%;
    padding: 3px;
    line-height: 12px;
    display: inline-block;
    width: 18px;
    height: 18px;
    text-align: center;
    position: absolute;
    left: 100%;
    top: 0;
}

.ind-foot-val .fa-equals {
    background-color: white;
}

.ind-foot-val .fa-arrow-up {
    transform: rotate(45deg);
}